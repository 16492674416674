resultController = ['$scope', 'qFactory', '$sce', '$stateParams', '$state', function($scope, qfactory, $sce, $stateParams, $state) {
    $scope.theme_index = $stateParams.theme_index;
    $scope.question_index = $stateParams.question_index;

    $scope.$on('LOAD', function() { $scope.loading = true; });
    $scope.$on('UNLOAD', function() { $scope.loading = false; });
    $scope.$on('startFiltering', function() { $scope.filtering = true; });
    $scope.$on('stopFiltering', function() { $scope.filtering = false; });

    $scope.qpage = "all";
    $scope.$emit('LOAD');
    $scope.filter = {};

    $scope.meetings_selected = [];
    $scope.meeting1_selected;
    $scope.compareAmount = [1];

    $selected_genderfilter = [];
    $selected_agefilter = [];
    $chooselanguage = ['da-dk', 'en-gb','fr-fr','it-it','ro-ro', 'no-no', 'bg-bg'];
    $scope.iso = $stateParams.iso;
    if($scope.qdata === undefined) {
        qfactory.getInit($scope.iso).then(
            function(response) {
                data = response.data
                $scope.qtext = response.data.text;
                $scope.qdata = response.data.results;
                $scope.meetings = response.data.meetings;

                //$scope.meetings_selected = [];
                $scope.meetings_selected[$scope.meetings[0].id] = true;

                $scope.groups = response.data.groups;
                $scope.languages = response.data.languages;
                $scope.language_selected = $scope.languages[$chooselanguage.indexOf($stateParams.iso)].iso;
                $scope.meeting1_selected = $scope.meetings[0].id;
                $scope.otext = response.data.othertext;

                //init filter
                $scope.qdata.themes.forEach( function(theme) {
                    theme.questions.forEach(function(question) {
                        question.answer_options.forEach(function(answer_option) {
                            $scope.filter[answer_option.id] = 0;
                        });
                    });
                });

                $scope.$emit('UNLOAD');
            },
            function(error) {
                loaded_data = false;
                console.log("The request failed: " + error);
            });
    } else {
        $scope.qdata = $scope.qdata;
    }

    $scope.downloadimage = function(theme, question){
        var querystring = "?";
        querystring += "theme=" + theme;
        querystring += "&question=" + question;
        querystring += "&compare1=" + $scope.compare1;
        querystring += "&language_selected=" + $("#language_selected").val(); //$scope.language_selected.iso;

        if($scope.compare2 != "")
            querystring += "&compare2=" + $scope.compare2;

        window.open("/image.jpg"+querystring);
        /*
        qfactory.createimage(querystring).then(
            function(response) {
                //console.log(response.data);
                window.open("/image/" + response.data + ".jpg");
            },
            function(error) {
                console.log(error)
            }
        );
        */

    }

    $scope.clearfilters  = function() {
        $scope.qdata.themes.forEach( function(theme) {
            theme.questions.forEach(function(question) {
                question.answer_options.forEach(function(answer_option) {
                    $scope.filter[answer_option.id] = 0;
                });
            });
        });
        doCompare();
    };


    $scope.showfilters = 0;
    $scope.showfilters_click = function() {
        if($scope.showfilters == 0) {
            $scope.clearfilters();
        }
    }

    $scope.checkclick = function() {
        $scope.$emit('startFiltering');
        doCompare();
    }


    $scope.sce = $sce;

    //$scope.meeting1_selected = "w1";
    $scope.meeting2_selected = null;
    $scope.group1_selected = null;
    $scope.group2_selected = null;
    $scope.compare1 = "w1";
    $scope.compare2 = "";

    $scope.selectMeetings = function(){
        $('#meeting_modal').foundation('reveal', 'open');
    }

    $scope.doPrint = function(){
				window.print();
    }

    $scope.select_meeting_close = function(){
        $('#meeting_modal').foundation('reveal', 'close');
    }

    $scope.roundNumber = function(number){
        return Math.round(number);
    }


    $scope.changedSelectedMeeting = function(s_meeting, selected){
        doCompare();
    }

    $scope.changedMeeting1 = function(item){
        $scope.$emit('LOAD');
        $scope.meeting1_selected = item;
        $scope.group1_selected = null;
        $scope.compare1 = item;

        doCompare();
    }
    $scope.changedGroup1 = function(item){
        $scope.$emit('LOAD');
        $scope.meeting1_selected = null;
        $scope.group1_selected = item;
        $scope.compare1 = item;

        doCompare();
    }


    $scope.changedMeeting2 = function(item){
        $scope.$emit('LOAD');
        $scope.meeting2_selected = item;
        $scope.group2_selected = null;
        $scope.compare2 = item;

        doCompare();
    }
    $scope.changedGroup2 = function(item){
        $scope.$emit('LOAD');
        $scope.meeting2_selected = null;
        $scope.group2_selected = item;
        $scope.compare2 = item;

        doCompare();
    }

    $scope.changedAge = function(selected_agefilter){
        $scope.$emit('LOAD');
        doCompare();
    }

    $scope.changedGender = function(selected_genderfilter){
        $scope.$emit('LOAD');
        doCompare();
    }



    $scope.changedLanguage = function(iso){
        $scope.$emit('LOAD');
        qfactory.getText(iso).then(
            function(response) {
                $scope.qtext= response.data["text"];
                $scope.otext= response.data["othertext"];
                $state.go("results", {iso:iso}, {notify: false})
                $scope.iso = iso;
                $scope.$emit('UNLOAD');
            },
            function(error) {
                loaded_data = false;
                console.log("The request failed: " + error);
                $scope.$emit('UNLOAD');
            }
        );
    }

    function doCompare() {

        //set filter
        $scope.filters = [];
//        for (var key in $scope.filter) {
//            if($scope.filter[key]) {
//                $scope.filters.push(key);
//            }
//        }
        if($scope.selected_agefilter) {
            $scope.filters.push($scope.selected_agefilter);
        }
        if($scope.selected_genderfilter) {
            $scope.filters.push($scope.selected_genderfilter);
        }

        if($scope.compare1 == $scope.compare2) {
            $scope.compare2 = "";
            $scope.meeting2_selected = null;
            $scope.group2_selected = null;
        }

        if($scope.compare2 == "") {
            $scope.compareAmount = [1];
        } else {
            $scope.compareAmount = [1, 2];
        }
        $scope.compare1 = $scope.meetings_selected;



        var get_meetings = [];
        var compareAmount = [];
        var num = 0;
        for (var key in $scope.meetings_selected) {
            if($scope.meetings_selected[key]) {
                num += 1;
                compareAmount.push(num);
                get_meetings.push(key);
            }
        }
        $scope.compareAmount = compareAmount;

        qfactory.getData(get_meetings, $scope.filters).then(
            function(response) {
                $scope.qdata= response.data;
                $scope.$emit('UNLOAD');
                $scope.$emit('stopFiltering');
            },
            function(error) {
                loaded_data = false;
                $scope.$emit('UNLOAD');
            }
        );
    }
}];



var wwviewsApp = angular.module('wwviewsApp', ['ui.router', 'ngAnimate']);

wwviewsApp.factory('RandomFactory', function () {
    return "Hello there";
});

wwviewsApp.factory("qFactory", function($http, $q) {

    var factory = {};
    var urlBase = '/api/';
    var canceler = $q.defer();

    factory.getInit = function (iso) {
        return $http.post(urlBase+"init", {"iso": iso});
    };
    factory.getText = function (iso) {
        return $http.post(urlBase+"text", {"iso": iso});
    };
    factory.getData = function (data1, filters) {
	      canceler.resolve();  // Aborts the $http request if it isn't finished.
        if(filters.length > 0) {
            return $http.post(urlBase+"results", {"data1": data1, "filters": filters}, [{timeout: canceler.promise}]);
        } else {
            return $http.post(urlBase+"results", {"data1": data1});
        }
    };
    factory.createimage = function (querystring) {
        console.log(querystring);
        return $http.get("/image"+querystring);
    };

    factory.getMapInit = function () {
        return $http.post(urlBase+"mapinit");
    };

    factory.getProgress = function () {
        return $http.post(urlBase+"progress");
    };

    factory.getNews = function () {
        return $http.post(urlBase+"news");
    };

		factory.postCustomer = function(customer) {
        return $http.post('/services', {
            name : customer.name,
            city : customer.city
        });
		}

		return factory;
});



wwviewsApp.config(function($stateProvider, $urlRouterProvider, $locationProvider) {
		// For any unmatched url, redirect to /state1
		$locationProvider.html5Mode(true);

    var data;
    var data_loaded = false;

		// Now set up the states
		$stateProvider
				.state('results', {
						url: "/:iso/results",
						templateUrl: "partials/compact",
						pageTitle: "Interact",
						controller: resultController
				})
				.state('results_theme', {
						url: "/:iso/results/:theme_index",
						templateUrl: "partials/compact",
						pageTitle: "Theme",
						controller: resultController
				})
				.state('results_question', {
						url: "/:iso/results/:theme_index/:question_index",
						templateUrl: "partials/compact",
						pageTitle: "Theme",
						controller: resultController
				});
});

controllers = {};

wwviewsApp.controller(controllers);


/*
d3DemoApp.directive('ghVisualization', function () {

});

*/
