$( document ).ready(function() {

    $('#select_meetings').click(function(evt) {
        $('#meeting_modal').foundation('reveal', 'open');
    });

//		function myFunction() {
    $('#print').click(function(evt) {


		});
});
